<template>
  <div class="container">
    <div class="main">
      <van-list v-model="listLoading" class="list" :finished="listFinished" :finished-text="listFinishedText" @load="onListLoad">
        <div class="list">
          <div v-for="item in list" :key="item.book_id" class="list-item" @click="onCode(item.hoemwork_qrcode)">
              <div class="list-item-weapp">
                <wx-open-launch-weapp
                  id="launch-btn"
                  :path='item.homework_qrcode'
                  username="gh_704767f97fd7"
                  @error="errorOpen"
                >
                  <script type="text/wxtag-template">
                    <div style='width:375px;height:132px;'></div>
                  </script>
                </wx-open-launch-weapp>
              </div>
              <div class="list-item-hd">
                <div class="list-item-hd-title">{{ item.homework_title }}</div>
                <van-icon name="ellipsis" />
              </div>
              <div class="list-item-bd">
                <div class="list-item-bd-avatar">
                  <van-image round :src="item.homework_employee.employee_avatar" />
                </div>
                <div class="list-item-bd-name">{{ item.homework_employee.employee_name }}</div>
                <div class="list-item-bd-date">{{ item.homework_date }}</div>
              </div>
              <div class="list-item-group van-hairline--bottom">
                {{ item.homework_class_name.length && item.homework_class_name.join(',') }}
              </div>
              <div class="list-item-ft">
                <div class="list-item-ft-count">待点评  {{ item.homework_count.no_comment }}</div>
                <div class="list-item-ft-count">未提交 {{ item.homework_count.no_commit }}</div>
                <div class="list-item-ft-count">未读 {{ item.homework_count.no_read }}</div>
              </div>

          </div>
        </div>
      </van-list>
      <van-popup v-model="show" position="center">
        <div class="qrcode">
          <van-image class="qrcode-img" :src="currentCode" />
          <div class="qrcode-title">长按识别小程序二维码</div>
        </div>
      </van-popup>
    </div>
  </div>
</template>
<script>

import PageMixin from '@/mixins/page'
import { Popup } from 'vant'

export default {
  name: 'TeacherHomework',
  components: {
    [Popup.name]: Popup
  },
  mixins: [PageMixin],
  data() {
    return {
      list: [],
      listLoading: false,
      listFinished: false,
      listFinishedText: '没有更多了',
      pageSize: 10,
      pageCurrent: 1,
      show: false,
      currentCode: ''
    }
  },
  methods: {
    onListLoad() {
      this.onPage()
    },
    onPage() {
      this.$api.employee_homework({ 'per-page': this.pageSize, page: this.pageCurrent }).then(res => {
        this.listLoading = false
        this.list = Number(res._meta.currentPage) === 1 ? res.items : this.list.concat(res.items)
        this.listFinished = this.list.length >= res._meta.totalCount
        this.listFinishedText = this.list.length ? '没有更多了' : '暂无相关数据~'
        this.pageCurrent += 1
      })
    },
    onCode(codeUrl) {
      this.show = true
      this.currentCode = codeUrl
    }
  },
}
</script>
<style lang="less" scoped>

    .container{
      padding: 10px 0;
    }
    .list{

    }
    .list-item{
      box-sizing: border-box;
      width: 100%;
      padding: 10px 15px 0;
      background: #fff;
      margin-bottom: 10px;
      position: relative;
      &-hd{
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 8px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        &-title{
          flex: 1;
        }
      }
      &-bd{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 8px;
        &-avatar{
          width: 30px;
          height: 30px;
          margin-right: 5px
        }
        &-name{
          font-size: 12px;
          color: #666;
          flex: 1;
        }
        &-date{
          font-size: 10px;
          color: #999;
        }
      }
      &-group{
        color: #999;
        font-size: 12px;
        padding-bottom: 8px;
      }
      &-ft{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 30px;
        &-count{
          flex: 1;
          color: #999;
          font-size: 12px;
          text-align: center;
        }
      }
      &-weapp{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        text-align: right;
      }
    }

    .qrcode{
      padding: 10px 15px;
      border-radius: 4px;
      &-img{
        width: 200px;
        height: 200px;
      }
      &-title{
        font-size: 16px;
        line-height: 2;
        color: #767676;
        text-align: center;
      }
    }
</style>

